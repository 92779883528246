.headingCards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__item {
		color: $white;
		padding: 17px 20px 50px;
		width: calc(25% - 1rem);
		position: relative;
		overflow: hidden;
		margin-bottom: 1rem;

		&:hover {
			.headingCards__overlay {
				transform: translateX(0);
			}

			.headingCards__image {
				transform: translateX(150%);
			}
		}
	}

	&__heading {
		position: relative;
		margin: 0;
		z-index: 2;
	}

	&__overlay {
		margin-top: 30px;
		transform: translateX(-150%);
		transition: ease 0.4s;
		position: relative;
		z-index: 2;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 1;
		transition: ease 0.6s;
	}

	&__link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
	}

	@media (max-width: 800px) {
		&__item {
			width: calc(50% - 0.5rem);
		}
	}

	@media (max-width: 610px) {
		&__item {
			width: 100%;
		}
	}
}
