.donorfy-form {

	table,
	tbody,
	tr,
	td {
		display: block;
	}

	table {
		width: 100%;

		&:nth-of-type(2) {
			tbody {
				display: block;
			}
		}
	}

	tbody {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	tr {
		margin-top: 1.2rem;
		width: 100%;

		@media (min-width: 540px) {
			width: calc(50% - 0.8rem);
		}

		&:nth-of-type(6) {
			display: none;
		}
	}

	input[type="text"],
	input[type="email"],
	textarea,
	select {
		border: 1px solid;
	}

	
	
	select {
		height: 42px;
		border-radius: 5px;
		padding: 0 10px;
		width: 100%;
	}

	label {
		font-size: 16px;
		font-weight: 400;
	}

	br {
		display: none;
	}

	#Amount {
		display: inline-block;
		width: calc(100% - 21px);
		border-radius: 0 5px 5px 0;
	}

	#AmountPrefix {
		height: 41px;
		padding: 19px 0 0;
		border-radius: 5px 0 0 5px;
	}

	label:has(#OneOffPayment),
	label:has(#RecurringPayment),
	label:has(#MonthlyPayment),
	label:has(#QuarterlyPayment),
	label:has(#AnnualPayment) {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		margin-top: 10px;

		span {
			width: calc(100% - 25px);
			margin: 0;
		}

		input {
			width: 15px;
			margin: 0;
			position: relative;
			bottom: 2px;
		}
	}

	div:has(#GiftAid) {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		margin-top: 10px;
		flex-wrap: wrap;

		p {
			width: 100%;
		}

		label {
			width: calc(100% - 25px);
			margin: 0;
		}

		input {
			width: 15px;
			margin: 0;
			position: relative;
			bottom: 2px;
		}
	}


	.kit-checkbox {
		div:has(.KeepInTouch) {
			display: flex;

			span {
				width: calc(100% - 25px);
				margin: 0;
			}

			input {
				width: 15px;
				margin: 0;
				position: relative;
				bottom: 2px;
			}

		}
	}	
}


.donorfy-button {
	margin-top: 1rem;
	display: inline-block;
}