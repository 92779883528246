.jobListings {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-right: -20px;

	&--col-2 {
		.jobListings__item {
			width: calc(50% - 20px);
		}
	}

	&__item {
		width: calc(33.333% - 20px);
		margin-right: 20px;
		margin-bottom: 20px;
		background-color: $color-one;
		color: $white;
		padding: 17px 16px 20px;
		text-decoration: none;
		transition: ease 0.4s;

		&:hover {
			opacity: 0.8;
		}
	}

	&__heading {
		margin: 0 0 0.8rem;
	}

	&__meta {
		margin-bottom: 3rem;
	}

	&__metaItem {
		margin: 0;
	}

	&__link {
		margin: 0;
		text-decoration: underline;

		svg {
			margin-left: 10px;
			max-width: 14px;
			top: 1px;
			position: relative;
		}
	}

	@media (max-width: 800px) {
		&__item {
			width: calc(50% - 20px);
		}
	}

	@media (max-width: 800px) {
		&__item {
			width: 100%;
		}
	}
}
