.squareList {
	list-style: none;
	padding: 0;

	li {
		position: relative;
		padding-left: 22px;
		margin-bottom: 12px;

		&::before {
			width: 10px;
			height: 10px;
			left: 0;
			top: 5px;
			position: absolute;
			content: "";
			background-color: $base-font-color;
		}
	}
}
