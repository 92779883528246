.jobInfo {
	margin-bottom: 20px;
	padding: 1.2rem 1.87rem;
	background-color: $grey;
	color: $white;

	&__item {
		border-bottom: 1px solid #888;
		margin: 0 0 13px;
		padding-bottom: 13px;

		&:last-of-type {
			border-bottom: 0;
			margin: 0;
			padding-bottom: 0;
		}
	}

	&__hilight {
		font-weight: 600;
	}
}
