.linkList {
	margin: 20px 0;

	&__item {
		display: block;
		border-top: 1px solid $lightGrey;
		text-decoration: none;
		position: relative;

		&::after {
			content: '';
			background-image: url(/images/arrow-grey.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 14px;
			height: 24px;
			position: absolute;
			margin: auto;
			top: 22px;
			bottom: 0;
			right: 30px;
			transition: ease 0.4s;
		}

		&:hover {
			.linkList__heading,
			.linkList__paragraph {
				opacity: 0.7;
			}

			&::after {
				right: 20px;
			}
		}
	}

	&__heading {
		margin-top: 23px;
		margin-bottom: 0;
		padding-right: 70px;
		transition: ease 0.4s;
	}

	&__paragraph {
		margin-top: 4px;
		margin-bottom: 21px;
		transition: ease 0.4s;
	}
}
