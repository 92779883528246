.accessInfo {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: -1.2rem;
		bottom: -1.2rem;
		left: -2rem;
		width: 1rem;
		background-color: $lightGrey;
	}

	&__heading {
		margin: 0 0 3rem;
	}

	&__subHeading {
		margin: 0;
	}

	p {
		margin: 0 0 1rem;
	}
}
