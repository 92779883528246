.cards {
	display: flex;
	flex-wrap: wrap;
	margin-right: -1rem;

	&__item {
		padding: 1rem;
		background-color: $color-one;
		color: $white;
		width: calc(33.33% - 1rem);
		margin: 0 1rem 1rem 0;
		text-decoration: none;
		transition: ease 0.4s;

		&:hover {
			opacity: 0.7;
		}
	}

	&__image {
		margin: -1rem -1rem 1rem;
		width: calc(100% + 2rem);
		max-width: 200%;
	}

	&__link {
		display: block;
		color: $white;
		margin-bottom: 0.5rem;
	}

	.button {
		margin-top: 1rem;
	}

	h3 {
		margin: 0;
	}

	@media (max-width: 900px) {
		&__item {
			width: calc(33.333% - 1rem);
		}
	}

	@media (max-width: 680px) {
		&__item {
			width: calc(50% - 1rem);
		}
	}

	@media (max-width: 480px) {
		margin-top: 3rem;
		margin-right: 0;

		&__item {
			width: 100%;
			margin-right: 0;
		}
	}
}
