.button {
	position: relative;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	padding: 0.9rem 1.5rem 0.6rem;
	border-radius: 5px;
	letter-spacing: 0.05rem;
	transition: ease 0.4s;
	font-size: 1rem;
	font-weight: 600;

	&--one {
		background-color: $color-one;
		color: $white;
		border: 2px solid $color-one;

		&--arrow {
			padding-right: 2.6rem;

			&::after {
				content: '';
				position: absolute;
				background-image: url(/images/arrow-white.svg);
				background-size: contain;
				background-repeat: no-repeat;
				width: 11px;
				height: 18px;
				transition: ease 0.4s;
				right: 1.4rem;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}

		&:hover {
			background-color: $white;
			color: $color-one;

			&::after {
				background-image: url(/images/arrow-purple.svg);
				right: 1rem;
			}
		}
	}

	&--white {
		background-color: $white;
		color: $color-one;
		border: 2px solid $white;

		&--arrow {
			padding-right: 2.6rem;

			&::after {
				content: '';
				position: absolute;
				background-image: url(/images/arrow-purple.svg);
				background-size: contain;
				background-repeat: no-repeat;
				width: 11px;
				height: 18px;
				transition: ease 0.4s;
				right: 1.4rem;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}

		&:hover {
			background-color: $color-one;
			color: $white;

			&::after {
				background-image: url(/images/arrow-white.svg);
				right: 1rem;
			}
		}
	}
}
