.video-embed {
	position: relative;
	margin-top: 2rem;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}