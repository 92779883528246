.resource-listing {
	margin-top: 2.5rem;
	
	&__subheading {
		font-weight: 600;
		font-size: 1.5rem;
		margin: 0 0 1rem;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 0 -0.625rem 3rem;
		list-style: none;
	}

	&__item {
		width: calc(100% - 1.25rem);
		margin: 0 0.625rem 1.2rem;
		position: relative;
		padding: 0;
		display: block;

		@media (min-width: 480px) {
			width: calc(50% - 1.25rem);
		}

		@media (min-width: 800px) {
			width: calc(33.33% - 1.25rem);
		}

		a {
			text-decoration: none;
			padding: 22px 40px 19px 20px;
			display: block;
		}

		h4 {
			color: #fff;
			margin: 0;
			position: relative;
			padding-right: 20px;
			z-index: 2;
		}

		&--color-two {
			background-color: $color-two;
		}

		&--color-three {
			background-color: $color-three;
		}

		&--color-four {
			background-color: $color-four;
		}

		&--color-five {
			background-color: $color-five;
		}

		&--color-six {
			background-color: $color-six;
		}

		&--color-seven {
			background-color: $color-seven;
		}

		&::after {
			content: '';
			background-image: url(/images/arrow-white.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 11px;
			height: 18px;
			margin: auto;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%) rotate(90deg);
			transition: ease 0.4s;
		}

		&:hover {
			&::after {
				top: calc(50% + 5px);
			}
		}

		&--external {
			&::after {
				transform: translateY(-50%);
			}

			&:hover {
				&::after {
					top: 50%;
					right: 15px;
				}
			}
		}
	}
}
