.story-slider {
	color: #fff;

	&__item {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;
		padding: 1rem 1rem 0;
	}

	&__text {
		width: calc(60% + 3rem);
		padding: 1rem 0 3.5rem 1.5rem;
	}

	&__quote {
		font-weight: 800;
		font-size: 1.6rem;
		margin: 0;
	}

	&__source {
		margin: 0.8rem 0 0;
	}

	&__image {
		width: 40%;
		margin-left: -3rem;
	}

	&__button {
		background-color: #fff;
		text-decoration: none;
		font-weight: 800;
		padding: 0.8rem 1.5rem;
		border-radius: 5px;
		border: 2px solid #fff;
		transition: background-color 0.2s;
		margin-top: 1.5rem;
		display: inline-block;

		&:hover {
			background-color: transparent;
			color: #fff !important;
		}
	}

	.slick-arrow {
		display: none !important;
	}

	.slick-dots {
		padding: 0;
		margin: 0;
		position: absolute;
		bottom: 1rem;
		left: 2.5rem;
		display: flex;
		list-style: none;

		li {
			margin-right: 8px;

			&.slick-active  {
				button {
					background-color: #fff;
				}
			}
		}

		button {
			font-size: 0;
			appearance: none;
			background-color: transparent;
			border: 2px solid #fff;
			border-radius: 100%;
			width: 10px;
			height: 10px;
			display: block;
			padding: 0;
		}
	}
}