.form {
	padding-bottom: 50px;

	input,
	label {
		width: 100%;
		display: block;
	}

	input[type="text"],
	input[type="email"],
	input[type="password"] {
		height: 42px;
		border-radius: 5px;
		border: 0;
		padding: 0 10px;
	}

	textarea {
		width: 100%;
		display: block;
		border-radius: 5px;
		border: 0;
		padding: 0 10px;
	}

	label {
		font-size: 1.25rem;
		font-weight: 600;
		letter-spacing: 0.05rem;
		margin: 0;
	}

	&__row {
		margin-bottom: 1rem;
	}

	&__checkboxPanel {
		// __checkboxPanel uses js to do the colour change - this was down to the way freeform spits out the checkboxes by default
		max-width: 800px;
		margin-bottom: 2rem;
		margin-top: 2rem;

		label {
			position: relative;
			background: $white;
			padding: 18px 20px 15px;
			font-size: 16px;
			transition: ease 0.4s;

			input {
				cursor: pointer;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				-webkit-appearance: none;
			}

			&.checked {
				color: #fff;
				background-color: $color-seven;

				&:hover {
					background-color: rgba(58, 184, 185, 0.6);
					color: #fff;
				}
			}

			&:first-of-type {
				border-radius: 5px 5px 0 0;
				overflow: hidden;
			}

			&:last-of-type {
				border-radius: 0 0 5px 5px;
				overflow: hidden;
			}

			&:hover {
				background-color: rgba(255, 255, 255, 0.6);
				color: #2e2e2e;
			}
		}
	}

	button {
		-webkit-appearance: none;
		background-color: $color-one;
		color: $white;
		border: 2px solid $color-one;
		cursor: pointer;
		padding: 0.9rem 1.5rem 0.6rem;
		border-radius: 5px;
		letter-spacing: 0.05rem;
		transition: ease 0.4s;
		font-size: 1rem;
		font-weight: 600;

		&:hover {
			background-color: $white;
			color: $color-one;
		}
	}
}

.signupPanel {
	padding: 1rem;

	&__heading {
		margin-top: 0;
	}

	form {
		position: relative;
	}

	.button {
		position: absolute;
		top: 0;
		right: 0;
		width: 33.3333%;
		padding-left: 0;
		padding-right: 0;
	}

	&__fields {
		width: 66.6666%;
		padding-right: 10px;

		input {
			width: 100%;
			height: 48px;
			padding: 0 10px;
			border-radius: 5px;
			border: 1px solid $grey;
		}
	}

	&__disable {
		opacity: 0.7;
		pointer-events: none;
	}
}


.form--login {
	margin-top: 2.5rem;
	max-width: 52rem;
	padding: 1.5rem;
	background-color: $lightGrey;
}