.hero {
	margin-bottom: 100px;
	padding-top: 10rem;
	margin-top: -1px;
	background-image: url(http://placehold.it/1600x550);
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	background-color: $midGrey;
	min-height: 550px;

	&__heading {
		text-align: center;
		color: $white;

		h1 {
			margin: 0;
			letter-spacing: 0.1rem;
			line-height: 1.3;
			text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
		}
	}

	&__buttons {
		text-align: center;
		margin-top: 2.8rem;
	}

	.iconPanels {
		position: relative;
		bottom: -126px;
	}

	@media (max-width: 1180px) {
		.iconPanels {
			bottom: -76px;
		}
	}

	@media (max-width: 800px) {
		padding-top: 5rem;
	}
}
