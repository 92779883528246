// EMPTY
img {
	max-width: 100%;
	display: block;
}

hr {
	width: 100%;
}

.text {
	&--right {
		text-align: right;
	}
}

.push {
	&--l {
		&--100 {
			margin-left: 100px !important;
		}
	}

	&--t {
		&--100 {
			margin-top: 100px !important;
		}

		&--70 {
			margin-top: 70px !important;
		}

		&--50 {
			margin-top: 50px !important;
		}

		&--30 {
			margin-top: 30px !important;
		}

		&--0 {
			margin-top: 0 !important;
		}
	}

	&--b {
		&--80 {
			margin-bottom: 80px !important;
		}

		&--50 {
			margin-bottom: 50px !important;
		}

		&--30 {
			margin-bottom: 30px !important;
		}
	}
}

.pad {
	&--t {
		&--10 {
			padding-top: 10px !important;
		}
	}

	&--b {
		&--10 {
			padding-bottom: 10px !important;
		}
	}

	&--l {
		&--0 {
			padding-left: 0 !important;
		}
	}
}

.color-one {
	color: $color-one;
	fill: $color-one;
}

.color-two {
	color: $color-two;
	fill: $color-two;
}

.color-three {
	color: $color-three;
	fill: $color-three;
}

.color-four {
	color: $color-four;
	fill: $color-four;
}

.color-five {
	color: $color-five;
	fill: $color-five;
}

.color-six {
	color: $color-six;
	fill: $color-six;
}

.color-seven {
	color: $color-seven;
	fill: $color-seven;
}

.color-white {
	color: $white;
	fill: $white;
}

.bg-color-one {
	background-color: $color-one !important;
}

.bg-color-two {
	background-color: $color-two !important;
}

.bg-color-three {
	background-color: $color-three !important;
}

.bg-color-four {
	background-color: $color-four !important;
}

.bg-color-five {
	background-color: $color-five !important;
}

.bg-color-six {
	background-color: $color-six !important;
}

.bg-color-seven {
	background-color: $color-seven !important;
}

.bg-lightGrey {
	background-color: $lightGrey !important;
}

.bg-white {
	background-color: $white;
}

// CATEGORY COLOR OVERWRITES
.category-color-one {
	h1,
	.testimonialPanel__testimonial p,
	.testimonialPanel__testimonial a,
	.accessInfo__heading {
		color: $color-one;
	}

	.linkBoxes,
	.squareList li::before,
	.accessInfo::after,
	.headlineImage__caption {
		background-color: $color-one;
	}

	.testimonialPanel__testimonial path {
		fill: $color-one;
	}

	.simple-card__button {
		background-color: $color-one;
		border: 1px solid $color-one;
	}
}

.category-color-two {
	h1,
	.testimonialPanel__testimonial p,
	.testimonialPanel__testimonial a,
	.accessInfo__heading {
		color: $color-two;
	}

	.linkBoxes,
	.squareList li::before,
	.accessInfo::after,
	.headlineImage__caption {
		background-color: $color-two;
	}

	.testimonialPanel__testimonial path {
		fill: $color-two;
	}

	.simple-card__button {
		background-color: $color-two;
		border: 1px solid $color-two;
	}
}

.category-color-three {
	h1,
	.testimonialPanel__testimonial p,
	.testimonialPanel__testimonial a,
	.accessInfo__heading {
		color: $color-three;
	}

	.linkBoxes,
	.squareList li::before,
	.accessInfo::after,
	.headlineImage__caption {
		background-color: $color-three;
	}

	.testimonialPanel__testimonial path {
		fill: $color-three;
	}

	.simple-card__button {
		background-color: $color-three;
		border: 1px solid $color-three;
	}
}

.category-color-four {
	h1,
	.testimonialPanel__testimonial p,
	.testimonialPanel__testimonial a,
	.accessInfo__heading {
		color: $color-four;
	}

	.linkBoxes,
	.squareList li::before,
	.accessInfo::after,
	.headlineImage__caption {
		background-color: $color-four;
	}

	.testimonialPanel__testimonial path {
		fill: $color-four;
	}

	.simple-card__button {
		background-color: $color-four;
		border: 1px solid $color-four;
	}
}

.category-color-five {
	h1,
	.testimonialPanel__testimonial p,
	.testimonialPanel__testimonial a,
	.accessInfo__heading {
		color: $color-five;
	}

	.linkBoxes,
	.squareList li::before,
	.accessInfo::after,
	.headlineImage__caption {
		background-color: $color-five;
	}

	.testimonialPanel__testimonial path {
		fill: $color-five;
	}

	.simple-card__button {
		background-color: $color-five;
		border: 1px solid $color-five;
	}
}

.category-color-six {
	h1,
	.testimonialPanel__testimonial p,
	.testimonialPanel__testimonial a,
	.accessInfo__heading {
		color: $color-six;
	}

	.linkBoxes,
	.squareList li::before,
	.accessInfo::after,
	.headlineImage__caption {
		background-color: $color-six;
	}

	.testimonialPanel__testimonial path {
		fill: $color-six;
	}

	.simple-card__button {
		background-color: $color-six;
		border: 1px solid $color-six;
	}
}

.category-color-seven {
	h1,
	.testimonialPanel__testimonial p,
	.testimonialPanel__testimonial a,
	.accessInfo__heading {
		color: $color-seven;
	}

	.linkBoxes,
	.squareList li::before,
	.accessInfo::after,
	.headlineImage__caption {
		background-color: $color-seven;
	}

	.testimonialPanel__testimonial path {
		fill: $color-seven;
	}

	.simple-card__button {
		background-color: $color-seven;
		border: 1px solid $color-seven;
	}
}



.columns {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 -0.9rem 0;

	> * {
		width: calc(100% - 1.8rem);
		margin: 1.8rem 0.9rem 0;

		@media (min-width: 440px) {
			width: calc(50% - 1.8rem);
		}

		@media (min-width: 680px) {
			width: calc(33.33% - 1.8rem);
		}

		@media (min-width: 1100px) {
			width: calc(25% - 1.8rem);
		}
	}


	@media (min-width: 1100px) {
		&--five {
			> * {
				width: calc(20% - 1.8rem);
			}
		}
	}

}

.hidden {
	display: none !important;
}