.teamCards {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-right: unset;

	&--secondary {
		background-color: $lightGrey;
		padding: 2.5rem;

		.teamCards__item {
			background-color: unset;
		}

		.teamCards__content {
			display: none;
		}
	}

	&__item {
		width: 100% !important;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		background-color: $white;
		margin-bottom: 40px;

		.teamCards__bio {
			display: block;
			width: 65%;
		}

		.teamCards__main {
			width: calc(35% - 20px);
		}

		.teamCards__content {
			background-color: $color-one;
		}

		&--large {
			.teamCards__bio {
				width: 50%;
			}

			.teamCards__main {
				width: calc(50% - 20px);
			}	
		}
	}

	&__image {
		width: 100%;
	}

	&__content {
		color: $white;
		padding: 12px 20px;
	}

	&__heading {
		margin: 0;
	}

	&__paragraph {
		margin: 0;
	}

	&__bio {
		display: none;
	}

	@media (max-width: 670px) {
		&__item {
			width: calc(50% - 20px);

			.teamCards__bio,
			.teamCards__main {
				width: 100%;
			}
		}
	}

	@media (max-width: 670px) {
		&__item {
			width: 100%;
		}
	}
}
