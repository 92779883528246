// empty file
.footer {
	margin-top: 2.5rem;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__item {
		width: calc(50% - 1.87rem);
	}

	&__heading {
		color: $color-one;
		border-bottom: 3px solid $color-one;
	}

	&__nav {
		margin-bottom: 20px;
	}

	&__navItem {
		font-size: 1rem;
		font-weight: 600;
		text-decoration: none;
		margin-right: 1.2rem;
		transition: ease 0.4s;

		&:hover {
			opacity: 0.7;
		}
	}

	&__lower {
		margin-top: 2rem;
		padding: 1.3rem 0 1rem;
		background-color: $grey;
		color: $white;
	}

	&__lowerLeft,
	&__lowerRight {
		width: calc(50% - 1.87rem);
	}

	&__lowerLeft {
		p {
			margin: 0 0 0.3rem;
		}
	}

	&__lowerRight {
		text-align: right;

		a {
			color: $white;
			text-decoration: none;
			margin: 0 0 0.3rem 1rem;
		}

		p a {
			margin-left: 0;
		}
	}

	.signupPanel {
		padding: 0;
	}

	@media (max-width: 600px) {
		&__item {
			width: 100%;
			margin-bottom: 1rem;
		}

		&__lowerLeft,
		&__lowerRight {
			width: 100%;
			text-align: left;

			a {
				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	}
}
