.linkBoxes {
	background-color: $grey;
	color: $white;
	position: relative;
	padding: 22px 20px 19px;
	margin-bottom: 15px;
	display: block;
	text-decoration: none;

	&--one {
		background-color: $color-one !important;
		color: $white;
	}

	&::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0);
		z-index: 1;
		transition: ease 0.4s;
	}

	&__heading {
		margin: 0;
		position: relative;
		padding-right: 20px;
		z-index: 2;

		&::after {
			content: '';
			background-image: url(/images/arrow-white.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 11px;
			height: 18px;
			margin: auto;
			position: absolute;
			bottom: 5px;
			margin-left: 10px;
			transition: ease 0.4s;
		}
	}

	&__paragraph {
		position: relative;
		margin-top: 10px;
		z-index: 2;
	}

	&:hover {
		&::after {
			background-color: rgba(0, 0, 0, 0.3);
		}

		.linkBoxes__heading {
			&::after {
				margin-left: 15px;
			}
		}
	}

	&--download {
		background-color: $color-one;

		.linkBoxes__heading {
			&::after {
				transform: rotate(90deg);
			}
		}

		&:hover {
			.linkBoxes__heading {
				&::after {
					margin-left: 10px;
					bottom: 2px;
				}
			}
		}
	}
}

.relatedServices {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;

	.linkBoxes {
		width: calc(25% - 15px);
		margin-right: 15px;
	}

	@media (max-width: 1200px) {
		.linkBoxes {
			width: calc(33.33% - 15px);
		}
	}

	@media (max-width: 630px) {
		.linkBoxes {
			width: calc(50% - 15px);
		}
	}

	@media (max-width: 420px) {
		.linkBoxes {
			width: 100%;

			&:nth-of-type(n+5) {
				display: none;
			}
		}
	}
}
