.popup {
	position: fixed;
	top: 10vh;
	left: 50%;
	transform: translate(-50%, 0);
	width: 100%;
	max-width: 500px;
	padding: 20px;
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	transition: ease 0.4s;

	&__content {
		padding: 40px;
		background-color: #fff;
		transform: translateY(30%);
		transition: ease 0.4s;
		max-height: 82vh;
		overflow: scroll;
	}

	&__button {
		margin-top: 20px;
	}

	&__image {
		display: block;
		width: 100%;
		max-width: 100%;
		margin-bottom: 30px;
	}

	&__close {
		position: absolute;
		top: 12px;
		right: 12px;
		max-width: 1.1rem;
	}

	&::after {
		content: "";
		position: absolute;
		top: -100vh;
		left: -100vw;
		width: 9999px;
		height: 9999px;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: -1;
		transition: ease 0.4s;
	}

	&--active {
		opacity: 1;
		pointer-events: all;

		.popup__content {
			transform: translateY(0);
		}
	}

	@media (max-width: 400px) {
		padding: 10px;

		p {
			font-size: 14px;
		}

		&__content {
			padding: 20px;
		}
	}
}
