.news {
	margin: 2rem 0;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__title {
		width: 100%;
		margin-bottom: 1.75rem;
	}

	&__item {
		width: calc(25% - 14px);
		background-color: $color-one;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		color: $white;
		cursor: pointer;
		margin-bottom: 20px;
		position: relative;
		overflow: hidden;
		transition: ease 0.4s;
		text-decoration: none;

		&--large {
			@media (min-width: 900px) {
				padding: 20px;
				background-image: url(http://placehold.it/300x210);
				width: calc(50% - 8px);

				.news__content {
					width: 50%;
					height: 100%;
					padding-bottom: 90px;
					background-color: transparent;

					&::after {
						content: 'Read More';
						font-size: 1rem;
						font-weight: 600;
						background-color: $white;
						color: $color-one;
						border: 2px solid $white;
						border-radius: 5px;
						letter-spacing: 0.05rem;
						transition: ease 0.4s;
						padding: 0.9rem 1.5rem 0.6rem;
						position: absolute;
						bottom: 20px;
						left: 20px;
					}
				}

				.news__image {
					display: none;
				}

				.news__heading {
					font-size: 1.25rem;
				}

				&::after {
					top: 20px;
					left: 20px;
					right: 20px;
					bottom: 20px;
					width: calc(50% - 20px);
					content: '';
					position: absolute;
					background-color: inherit;
					opacity: 0.8;
					transition: ease 0.4s;
				}

				&:hover {
					&::after {
						opacity: 1;
						transform: scale(1.03);
					}

					.news__content {
						&::after {
							background-color: $color-one;
							color: $white;
						}
					}
				}
			}

			@media (max-width: 900px) {
				background-image: unset !important;
			}
		}

		&:hover {
			.news__image {
				transform: scale(1);
				opacity: 0.8;
			}
		}
	}

	&__image {
		min-height: 100px;
		background-color: $lightGrey;
		width: 100%;
		transform: scale(1.1);
		transition: ease 0.4s;
	}

	&__content {
		padding: 20px;
		background-color: inherit;
		position: relative;
		z-index: 1;
	}

	&__heading {
		font-size: 1rem;
		font-weight: 600;
		margin: 0 0 4px;
		letter-spacing: 0.04rem;
	}

	&__meta {
		margin: 0 0 1rem;
	}

	&__paragraph {
		letter-spacing: 0.02rem;
		line-height: 1.4;
	}

	@media (max-width: 899px) {
		&__item {
			width: calc(33.3333% - 14px);
		}
	}

	@media (max-width: 600px) {
		&__item {
			width: calc(50% - 6px);
			margin-bottom: 12px;
		}

		&--preview {
			.news__item {
				&:last-of-type {
					display: none;
				}
			}
		}
	}
}
