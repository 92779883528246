*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	transition: ease 0.4s;

	&.modalBackground {
		&::before {
			position: fixed;
			height: 100%;
			width: 100%;
			content: '';
			background-color: rgba(0, 0, 0, 0.6);
			z-index: 10;
		}
	}

	@media (max-width: 840px) {
		overflow-x: hidden;
	}
}

.container {
	max-width: 1300px;
	padding-left: 20px;
	padding-right: 20px;
	margin: auto;

	&--narrow {
		max-width: 900px;
	}

	&--layout {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	&--textpadding {
		margin-top: 20px;
	}

	&__item {
		&--quarter {
			width: calc(25% - 20px);
		}

		&--threeQuarters {
			width: calc(75% - 5px);
		}

		&--third {
			width: calc(33.333% - 20px);
		}

		&--twoThirds {
			width: 66.666%;
		}
	}

	&__panel {
		padding: 1.2rem 1.87rem;
		margin-bottom: 1.25rem;
		position: relative;

		&--fixed {
			position: sticky;
			top: 20px;
			margin-top: -4px;
			padding-right: 0;
		}

		&--background {
			margin: 80px 0;

			&::after {
				position: absolute;
				left: -10vw;
				top: -50px;
				bottom: -50px;
				background-color: #e3e3e3;
				content: '';
				width: 200vw;
				z-index: -1;
			}
		}
	}

	@media (max-width: 850px) {
		&__item {
			width: 100% !important;

			&:first-of-type {
				margin-bottom: 20px;
			}
		}

		&__panel {
			&--fixed {
				padding: 1.2rem 0;
			}
		}

		.push--l--100 {
			margin-left: 0 !important;
		}
	}
}
