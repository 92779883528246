@font-face {
	font-family: 'DHI';
	src: url('/fonts/DHI-Regular.eot');
	src: url('/fonts/DHI-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/DHI-Regular.woff2') format('woff2'),
		url('/fonts/DHI-Regular.woff') format('woff'),
		url('/fonts/DHI-Regular.ttf') format('truetype'),
		url('/fonts/DHI-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'DHI';
	src: url('/fonts/DHI-Bold.eot');
	src: url('/fonts/DHI-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/DHI-Bold.woff2') format('woff2'),
		url('/fonts/DHI-Bold.woff') format('woff'),
		url('/fonts/DHI-Bold.ttf') format('truetype'),
		url('/fonts/DHI-Bold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}

html {
	color: $base-font-color;
	font-family: $sans-serif;
	font-size: $base-font-size;
	line-height: 1.3;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;

	@media (max-width: 800px) {
		font-size: 15px;
	}
}

a,
.js-link {
	color: $base-font-color;

	&:hover {
		text-decoration: none;
	}
}

p a {
	color: inherit;
}

.heading-one {
	font-size: 2.9rem;
	letter-spacing: 0.05rem;
	font-weight: 600;

	@media (max-width: 800px) {
		font-size: 2rem;
	}
}

.heading-two {
	font-size: 2.06rem;
	letter-spacing: 0.06rem;
	font-weight: 600;
	margin-bottom: 11px;
	margin-top: 0;

	@media (max-width: 760px) {
		font-size: 1.6rem;
	}
}

.heading-three {
	font-size: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.05rem;

	@media (max-width: 700px) {
		font-size: 1.1rem;
	}
}

.heading-four {
	font-size: 1.6rem;
	font-weight: 600;
	letter-spacing: 0.05rem;

	@media (max-width: 700px) {
		font-size: 1.35rem;
	}
}

.paragraph {
	font-size: 1rem;
	font-weight: 400;
	margin-top: 0;

	&--icon {
		padding: 0.5rem 0 0.5rem 60px;
		margin: 0 0 1.5rem;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			background-repeat: no-repeat;
			background-position: 0 50%;
			background-size: contain;
			width: 50px;
			height: 50px;
		}

		&--money {
			&::after {
				background-image: url(/images/coins.svg);
			}
		}
	}
}

.paragraph-small {
	font-size: 0.87rem;
	font-weight: 400;
}

.paragraph-large {
	font-size: 1.125rem;
	font-weight: 400;
}
