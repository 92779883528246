$hero-two-break-one: 800px;

.hero-two {
	padding: 2rem 0 0 0;

	@media (min-width: $hero-two-break-one) {
		padding: 4rem 2rem 0 0;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__text {
		width: 100%;
		color: $white;
		padding-bottom: 3rem;

		@media (min-width: $hero-two-break-one) {
			width: calc(50% - 2rem);
		}
	}

	&__heading {
		font-size: 32px;
		margin: 0;

		@media (min-width: $hero-two-break-one) {
			font-size: 46px;
		}
	}

	&__copy {
		font-size: 18px;
		margin: 1.5ex 0 0;

		@media (min-width: $hero-two-break-one) {
			font-size: 22px;
		}
	}

	&__button {
		text-decoration: none;
		margin: 3.8ex 0 0;
		display: inline-block;
		background-color: $white;
		padding: 1ex 2.5ex;
		font-weight: 600;
		border-radius: 0.4ex;
		border: 1px solid $white;

		&:hover {
			background-color: currentColor;

			span {
				filter: brightness(100);
			}
		}
	}

	&__image-col {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;

		@media (min-width: $hero-two-break-one) {
			width: calc(50% - 2rem);
		}
	}

	&__image {
		display: block;
		width: 100%;

		img {}
	}
}