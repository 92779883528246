.iconPanels {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&--single {
		margin-top: 1.25rem;

		.iconPanels__item {
			width: 100%;
		}
	}

	&__item {
		width: calc(50% - 10px);
		margin-bottom: 20px;
		background-color: $lightGrey;
		padding: 1rem 1.87rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&--one {
			background-color: $color-one;
			color: $white;
		}

		&--outline {
			color: $base-font-color;
			background-color: transparent;
			border: 1px solid $lightGrey;
		}
	}

	&__icon {
		background-image: url(http://placehold.it/300);
		background-size: 80%;
		background-repeat: no-repeat;
		background-position: 50%;
		width: 33.333%;
		padding-bottom: 25%;
	}

	&__content {
		width: calc(66.666% - 20px);
	}

	&__intro {
		margin-top: 0;
		margin-bottom: 5px;
	}

	&__hilight {
		font-size: 2.1rem;
	}

	&__paragraph {
		font-weight: 600;
		margin: 0;
	}

	&__button {
		margin-top: 15px;
	}

	@media (max-width: 800px) {
		&__item {
			width: 100%;
		}
	}
}
