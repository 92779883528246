.self-help-tool {
	margin-top: 4rem;

	.container {
		max-width: 850px;
	}


	.field__instructions {
		display: none;
	}

	.form__row {
		background-color: #F5F4F5;
		padding: 2rem;
		border-radius: 4px;
		margin-bottom: 2rem;

		.form__row {
			margin-bottom: 0;
			padding: 1rem 2rem;

			.field__label {
				font-size: 18px;
			}
		}

		fieldset {
			border: 0;
			margin: 0;
			padding: 0;
		}
	}

	.field__label {
		font-weight: 600;
		font-size: 28px;
		color: $color-three;
		margin-bottom: 0.7ex;
		display: block;
	}

	select {
		display: block;
		width: 100%;
		padding: 1.4ex;
	}

	.field-checkbox-item {
		margin-bottom: 0.5rem;
	}

	.button-wrapper {
		display: flex;
		justify-content: flex-end;
	}

	.tool-button {
		position: relative;

		input {
			appearance: none;
			border: 0;
			background-color: $color-three;
			color: $white;
			font-weight: 600;
			border: 1px solid $color-three;
			padding: 1.25ex 2.1rem 1ex 2ex;
			transition: 0.2s;
			cursor: pointer;
			border-radius: 4px;
		}

		svg {
			position: absolute;
			top: 1.4ex;
			right: 1.3ex;
			width: 1rem;
			pointer-events: none;
			transition: 0.2s;
		}

		&:hover {
			input {
				background-color: $white;
				color: $color-three;
			}

			svg path {
				fill: $color-three;
			}
		}
	}
}