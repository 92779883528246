.pagination {
	width: 100%;
	padding: 0;
	margin: 50px 0 0;
	text-align: center;
	font-size: 14px;
	list-style: none;

	&-container {
		margin: 0 auto;
	}

	&__item {
		color: #767676;
		text-decoration: none;
		padding: 8px;
		padding: 5px 10px 7px;
		border-radius: 5px;
		display: inline-block;
	}

	&__current {
		color: $white;
		position: relative;

		&::before {
			position: absolute;
			content: '';
			background-color: $color-one;
			border-radius: 100px;
			width: 30px;
			height: 30px;
			left: 50%;
			top: 50%;
			z-index: -1;
			transform: translate(-50%, -50%);
			margin-top: -1px;
		}
	}

	&__next,
	&__previous {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 0 10px 12px;
		border-color: transparent transparent transparent $color-one;
		display: inline-block;
		border-radius: 0;
		padding: 0;
		margin: 0 0 -5px 50px;
	}

	&__previous {
		transform: rotate(180deg);
		margin: 0 50px -5px 0;
	}
}
