.introPanel {
	padding-top: 2.5rem;
	margin-bottom: 20px;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__left {
		width: calc(66.666% - 20px);

		p {
			margin-top: 0;
		}
	}

	&__right {
		width: calc(33.333% - 20px);
	}

	&__callout {
		margin-left: 70px;
		padding: 2px 0 0 20px;
		border-left: 10px solid $color-one;
	}

	&__heading {
		width: 100%;
		font-size: 3rem;
		margin: 0 0 5px;
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		align-items: flex-start;

		h1 {
			margin: 0;
			width: 100%;
			padding-right: 40px;
		}
	}

	&__image {
		width: 100%;
		font-size: 10px;
		text-align: right;

		img {
			width: 100%;
			max-width: 200px;
			display: inline;
		}
	}

	&__video-wrapper {
		margin: 2rem 0 1rem;

		@media (min-width: 650px) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__video {
		@media (min-width: 650px) {
			width: 60%;
		}

		figure {
			margin: 0;
		}

		iframe {
			width: 100%;
		}
	}

	&__video-copy {
		padding: 1rem 0 0;

		@media (min-width: 650px) {
			width: 40%;
			padding: 0 0 0 1rem;
		}
	}

	@media (max-width: 700px) {
		&__left,
		&__right {
			width: 100%;
			margin-bottom: 0.8rem;
		}

		&__callout {
			margin-left: 0;
		}
	}
}
