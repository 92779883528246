.timeline {
	max-width: 920px;
	margin: auto;
	position: relative;
	overflow: hidden;
	padding-bottom: 200px;

	&::after {
		content: '';
		position: absolute;
		width: 2px;
		background-color: $color-one;
		top: 25px;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}

	&__item {
		display: flex;
		margin-bottom: 25px;
		position: relative;
		padding: 0 1rem;

		&:nth-of-type(even) {
			flex-direction: row-reverse;

			.timeline__copy {
				margin: 0 0 0 120px;

				&::after {
					right: unset;
					left: -23px;
				}
			}
		}

		&:last-of-type {
			.timeline__dot {
				&::before {
					content: '';
					position: absolute;
					width: 6px;
					background-color: $white;
					height: 9999px;
					top: 1.6rem;
					left: 0;
					right: 0;
					margin: auto;
				}
			}
		}
	}

	&__copy {
		position: relative;
		width: calc(50% - 80px);
		background-color: $lightGrey;
		padding: 22px;
		margin: 0 120px 0 0;
		min-height: 68px;
		transition: ease 0.4s;

		&::after {
			content: '';
			position: absolute;
			width: 3rem;
			height: 3rem;
			background-color: inherit;
			top: 10px;
			right: -23px;
			transform: rotate(45deg);
		}
	}

	&__paragraph {
		margin: 0;
		position: relative;
		z-index: 1;
	}

	&__image {
		width: 100%;
		margin-top: 15px;
	}

	&__dot {
		width: 1.6rem;
		height: 1.6rem;
		border-radius: 100px;
		background-color: $color-two;
		position: absolute;
		left: 0;
		right: 0;
		top: 20px;
		margin: auto;
		z-index: 1;
	}

	&__heading {
		margin: 18px 0 0;
	}

	&__active {
		.timeline__copy {
			transform: scale(1.09);
		}
	}

	@media (max-width: 820px) {
		&__paragraph {
			font-size: 1rem;
		}

		&__copy {
			padding: 15px;
			width: calc(50% - 60px);
			margin: 0 87px 0 0;
		}

		&__item {
			&:nth-of-type(even) {
				.timeline__copy {
					margin: 0 0 0 87px;
				}
			}
		}
	}

	@media (max-width: 580px) {
		text-align: center;

		&::after {
			z-index: -1;
			bottom: 30px;
		}

		&__dot {
			&::before {
				display: none;
			}
		}

		&__item {
			padding-top: 44px;
			flex-wrap: wrap;
			flex-direction: column-reverse;

			&:nth-of-type(even) {
				flex-direction: column-reverse;

				.timeline__copy {
					margin: 0;
				}
			}
		}

		&__copy {
			margin: 0;
			width: 100%;

			&::after {
				display: none;
			}
		}

		&__heading {
			background-color: $white;
			padding: 10px 0 5px;
		}
	}
}
