.accordion {
	border-bottom: 1px solid;
	padding-bottom: 0.8rem;
	margin-top: 3rem;

	+ .accordion {
		margin-top: 0.8rem;
	}

	&__trigger {
		position: relative;
		appearance: none;
		background-color: transparent;
		border: unset;
		cursor: pointer;
		padding: 0;
		margin: 0;
		width: 100%;
		text-align: left;

		&::after {
			display: none;
		}
	}

	&__heading {
		padding: 0;
		margin: 0;
	}

	&__arrow {
		background-image: url('/images/arrow-grey.svg');
		background-size: 0.7rem;
		background-repeat: no-repeat;
		background-position: 50%;
		width: 1.5rem;
		height: 1.5rem;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%) rotate(90deg);
	}

	.intro-accordion__button--active {
		.accordion__arrow {
			transform: translateY(-50%) rotate(-90deg);
		}
	}

	&__draw {}

	&__downloads {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		> * {
			width: calc(50% - 0.5rem);
			margin: 1rem 0 0;
		}
	}
}
