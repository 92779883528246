.testimonialPanel {
	text-decoration: none;
	display: block;
	width: 100%;
	padding-bottom: 69%;
	position: relative;
	background-image: url(http://placehold.it/835x575);
	background-color: $color-one;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	min-height: 400px;

	&__testimonial {
		position: absolute;
		bottom: 100px;
		left: 70px;
		width: 220px;
		text-align: center;

		&::before {
			position: absolute;
			content: '';
			width: 260px;
			height: 260px;
			background-color: rgba(255, 255, 255, 0.9);
			left: 50%;
			top: 57%;
			transform: translate(-50%, -50%);
			z-index: 0;
			border-radius: 1000px;
			transition: ease 0.4s;
		}
	}

	&__paragraph,
	&__link,
	svg {
		position: relative;
		text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
	}

	&__link {
		font-weight: 600;
	}

	&:hover {
		.testimonialPanel__testimonial {
			&::before {
				transform: translate(-50%, -50%) scale(1.15);
				background-color: rgba(255, 255, 255, 0.75);
			}
		}
	}
}

.testimonialContainer {
	&--columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.testimonialPanel__paragraph,
		.testimonialPanel__link {
			text-shadow: 0 0 0 rgba(255, 255, 255, 0);
		}

		.testimonialPanel {
			width: calc(50% - 10px);
			padding: 30% 0 0;
			min-height: auto;
			background-size: 100%;
			background-position: 50% 0;
			transition: ease 0.4s;

			&:hover {
				background-size: 105%;
			}

			@media (max-width: 570px) {
				width: 100%;
				margin-bottom: 1rem;
				padding: 60% 0 0;
			}
		}

		.testimonialPanel__testimonial {
			position: relative;
			left: 0;
			bottom: 0;
			width: 100%;
			color: $white;
			background-color: inherit;
			padding: 30px 20px;

			svg {
				transform: scale(0.7);
			}

			a {
				color: $white;
			}

			&::before {
				display: none;
			}
		}

		.testimonialPanel__paragraph {
			margin-top: 10px;
		}
	}

	&--columnsSmall {
		.testimonialPanel {
			width: calc(33.33% - 10px);
			padding: 22% 0 0;
			margin-bottom: 1rem;

			@media (max-width: 840px) {
				width: calc(50% - 10px);
			}

			@media (max-width: 570px) {
				width: 100%;
				padding: 60% 0 0;
			}
		}
	}
}
