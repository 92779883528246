.intro-accordion {
	position: relative;
	padding-bottom: 4rem;
	border-bottom: 1px solid;

	&__intro {
		max-width: 840px;
	}

	&__button {
		appearance: none;
		border: 0;
		background-color: transparent;
		text-decoration: underline;
		position: absolute;
		bottom: 2.5rem;
		padding: 0;
		text-align: left;

		&::after {
			content: "Read More";
		}

		&--active {
			&::after {
				content: "Read Less";
			}
		}
	}

	&__content {
		> * {
			max-width: 840px;

			&:first-of-type {
				margin-top: 0;
			}
		}
	}
}

