.simple-card {
	text-decoration: none;
	text-align: center;
	display: block;

	&.category-color-three {
		&:hover,
		&:focus {
			.simple-card__button {
				color: $color-three;
			}
		}
	}

	&:hover,
	&:focus {
		.simple-card__button {
			background-color: $white;
		}
	}

	&__heading {
		font-size: 1.37rem;
		margin: 1.3ex 0 0 ;
	}

	&__image {
		aspect-ratio: 1.3;
		background-size: cover;
		background-position: 50%;
		display: block;
		width: 100%;

		img {
			height: 0;
			width: 0;
			overflow: hidden;
		}
	}

	&__button {
		display: block;
		font-weight: 800;
		color: $white;
		background-color: $color-one;
		width: 100%;
		padding: 0.7rem;
		border-radius: 5px;
		transition: 0.3s;
		margin: 1.3ex 0 0;
	}
}

