.breadcrumbs {
	margin-bottom: -36px;
	padding-top: 1rem;
	position: relative;

	&__item {
		display: inline;
		padding-right: 20px;
		position: relative;
		pointer-events: none;

		a {
			text-decoration: none;
		}

		&:first-of-type {
			pointer-events: all;
		}

		&::after {
			content: '';
			position: absolute;
			background-image: url(/images/arrow-grey.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 9px;
			height: 16px;
			top: 2px;
			right: 4px;
		}

		&:last-of-type {
			padding-right: 0;

			&::after {
				display: none;
			}
		}
	}

	&__logo {
		max-height: 88px;
		position: absolute;
		right: 20px;
		top: 15px;
	}

	@media (max-width: 550px) {
		&__logo {
			display: none;
		}
	}
}
