.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.5);
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	backdrop-filter: blur(5px);

	&--active {
		opacity: 1;
		pointer-events: all;
	}

	fieldset {
		border: 0;
		padding: 0;
		margin: 0;
	}

	.field__input {
		display: block;
		width: 100%;
		padding: 1.1ex;
	}

	.button {
		color: $white;
		background-color: $color-one;
		appearance: none;
		border: 1px solid $color-one;

		&:hover {
			color: $color-one;
			background-color: $white;
		}
	}

	&__content {
		position: relative;
		background-color: $white;
		max-width: 34rem;
		margin: 8rem auto 0;
		padding: 3rem;
		box-shadow: 0 15px 20px rgba(0, 0, 0, 0.15);
		animation: modalIn;
		animation-duration: 1s;
	}

	&__close {
		background: transparent;
		border: 0;
		padding: 0;
		width: 1rem;
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
	}

	&__heading {
		margin-top: 0;
	}

	&__intro {
		margin: 0;
	}

	&__form {
		margin-top: 1rem;

		label,
		input {
			width: 100%;
		}

		label {}
	}

	&__form-row {
		display: flex;
		flex-wrap: wrap;

		input {
			width: calc(100% - 100px);
			border-radius: 0.5ex 0 0 0.5ex;
			border: 1px solid;
		}

		button {
			width: 100px;
			margin: 0 0 0 -2px;
			border-radius: 0 0.5ex 0.5ex 0;
			cursor: pointer;
		}
	}

	&__password-reset {
		margin-top: 1.8ex;
		display: block;
		text-decoration: underline;
	}
}

@keyframes modalIn {
	from {
		top: 4rem;
		opacity: 0;
	}

	to {
		top: 0;
		opacity: 1;
	}
}
