.header {
	border-bottom: 1px solid $midGrey;
	position: fixed;
	width: 100%;
	background-color: $white;
	z-index: 10;
	top: 0;

	&__container {
		padding-top: 20px;
		padding-bottom: 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__logo {
		max-width: 250px;
		transition: ease 0.4s;

		img {
			transition: ease 0.4s;
		}
	}

	&__search {
		width: 100%;
		text-align: right;
		margin-bottom: 20px;
		opacity: 0.5;
		color: $midGrey;
		font-weight: 600;

		form {
			display: inline-block;
			border-bottom: 1px solid currentColor;
			padding: 0 0.5rem 0.5rem;
		}

		input {
			border: 0;
			background-color: transparent;
		}
	}

	&__searchIcon {
		background-image: url('/svg/search.svg');
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		color: transparent;
		position: relative;
		top: -2px;
	}

	.button {
		margin-top: -6px;
	}

	&__navTrigger {
		display: none;
		position: relative;
		z-index: 10;
		width: 33px;
		height: 29px;
		padding: 0 2px;
		overflow: hidden;

		span {
			width: 29px;
			height: 3px;
			background-color: $base-font-color;
			display: block;
			margin: 5px 0;
			transition: ease 0.4s;
			border-radius: 100px;
			position: relative;

			&::after {
				content: '';
				width: 100%;
				height: 100%;
				background-color: $base-font-color;
				position: absolute;
				transition: ease 0.2s;
				transform: rotate(0);
				display: none;
			}
		}

		&:hover {
			span {
				opacity: 0.7;

				&:nth-of-type(1) {
					left: 2px;
				}

				&:nth-of-type(3) {
					left: -2px;
				}
			}
		}

		&--active {
			span {
				&:nth-of-type(1) {
					transform: translateX(40px);
				}

				&:nth-of-type(2) {
					transform: rotate(45deg);

					&::after {
						display: block;
					}
				}

				&:nth-of-type(3) {
					transform: translateX(-40px);
				}

				&::after {
					transform: rotate(90deg);
				}
			}
		}
	}

	&__nav {
		position: relative;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		margin-left: 40px;
	}

	&__navItem {
		transition: ease 0.4s;
		margin-right: 10px;

		a {
			text-decoration: none;
			padding: 1rem;
			transition: ease 0.4s;
		}

		&:hover {
			> a {
				opacity: 0.7;
			}

			.header__dropdown {
				right: 0;
				pointer-events: all;
				opacity: 1;
			}
		}

		&--order {
			.header__dropdownItem {
				order: 1;

				&:nth-of-type(2) {
					order: 4;
				}

				&:nth-of-type(3) {
					order: 2;
				}

				&:nth-of-type(4) {
					order: 5;
				}

				&:nth-of-type(5) {
					order: 3;
				}

				&:nth-of-type(6) {
					order: 6;
				}
			}
		}
	}

	&__dropdown {
		position: absolute;
		right: 0;
		top: 87px;
		width: 60vw;
		z-index: 1;
		border-top: 40px solid transparent;
		pointer-events: none;
		transition: ease 0.4s;
		opacity: 0;

		&::after,
		&::before {
			content: '';
			position: absolute;
			width: 1px;
			background-color: $white;
			top: 1rem;
			bottom: 1rem;
			left: 33.33%;
			opacity: 0.6;
		}

		&::after {
			left: 66.66%;
		}
	}

	&__dropdownContent {
		background-color: $color-one;
		padding: 1rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__dropdownItem {
		width: calc(33.3333% - 1rem);
		display: block;
		color: $white;
		transition: ease 0.4s;

		&:hover {
			opacity: 0.7;
		}
	}

	&--scroll {
		@media (min-width: 840px) {
			.header__logo {
				margin-top: -5px;
				max-width: 150px;
				background-image: url('/images/dhi-logo-small.png');
				background-size: 96%;
				background-repeat: no-repeat;
				background-position: 50%;

				img {
					opacity: 0;
				}
			}

			.header__container {
				padding-top: 20px;
				padding-bottom: 14px;
			}

			.header__search {
				display: none;
			}

			.header__navItem {
				a {
					font-size: 14px;
				}
			}

			.header__dropdown {
				top: 34px;
				border-top: 30px solid transparent;
			}
		}
	}

	@media (max-width: 839px) {
		position: relative;
		top: unset;

		&__navTrigger {
			display: block;
		}

		&__nav {
			background-color: $color-one;
			padding: 1rem;
			position: absolute;
			top: 100%;
			right: 0;
			display: block;
			margin-left: 0;
			max-width: 360px;
			transform: translateX(120%);
			transition: ease 0.4s;

			&--active {
				transform: unset;
			}
		}

		&__dropdown {
			position: relative;
			right: unset;
			top: unset;
			width: 100%;
			border-top: 0;
			pointer-events: all;
			opacity: 1;
			margin-bottom: 15px;
			margin-top: 5px;

			&::after,
			&::before {
				display: none;
			}
		}

		&__dropdownContent {
			background-color: transparent;
			padding: 0;
			display: block;

			.header__dropdownItem {
				background-color: $color-one;
				width: 100%;
				padding: 8px 16px;
				transition: ease 0.4s;
				margin-left: -16px;

				&:hover {
					background-color: lighten($color-one, 20%);
				}
			}
		}

		&__navItem {
			a {
				padding: 0 0 17px;
				color: $white;
			}
		}

		&__search {
			form {
				width: 100%;
				text-align: left;
			}

			input {
				width: 78%;
				color: $white;
			}

			.header__searchIcon {
				width: 20%;
				background-position: 100% 50%;
				color: $color-one;
			}
		}

		.button {
			margin-top: 1rem;
			background-color: $white;
			color: $color-one;
			width: 100%;
			max-width: 250px;
			text-align: center;
			padding: 0.7rem 0;
		}
	}

	@media (max-width: 500px) {
		&__logo {
			background-image: url(/images/dhi-logo-small.png);
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat;
			max-width: 200px;
			min-width: 160px;
			width: 50%;

			img {
				opacity: 0;
			}
		}
	}
}
