.arrowPanels {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__item {
		width: calc(33.333% - 13px);
		background-color: $lightGrey;
		overflow: hidden;
		color: $white;
		margin-bottom: 20px;
		position: relative;
		text-decoration: none;

		&--color-two {
			background-color: $color-two;
		}

		&--color-three {
			background-color: $color-three;
		}

		&--color-four {
			background-color: $color-four;
		}

		&--color-five {
			background-color: $color-five;
		}

		&--color-six {
			background-color: $color-six;
		}

		&--color-seven {
			background-color: $color-seven;
		}

		&:hover {
			.arrowPanels__image {
				opacity: 0.4;
				transform: scale(1.02);
			}

			.arrowPanels__overlay {
				transform: translateX(0);
			}
		}
	}

	&__image {
		width: 100%;
		transition: ease 0.4s;
		transform: scale(1.1);
		min-height: 100px;
		background-color: $lightGrey;
		align-self: flex-start;
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 1rem;
		transition: ease 0.4s;
		transform: translateX(-100%);
		z-index: 1;
		width: 100%;

		p {
			margin-top: 0;
		}

		a {
			color: inherit;
			font-weight: 600;
		}
	}

	&__content {
		background-color: inherit;
		color: inherit;
		padding: 0.9rem 1.25rem 0.6rem;
		position: relative;
		width: 100%;

		h3 {
			margin: 0;
		}

		&::after {
			content: '';
			position: absolute;
			width: 25px;
			height: 25px;
			top: 0;
			right: 0;
			transform: translate(50%, -50%) rotate(45deg);
			background: inherit;
		}
	}

	@media (max-width: 600px) {
		&__item {
			display: flex;
			flex-wrap: wrap;
			width: calc(50% - 6px);
			margin-bottom: 12px;
		}

		&__overlay {
			padding-top: 0;
			position: relative;
			transform: translateX(0);
			order: 2;
		}
	}
}
