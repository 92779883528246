.videoContainer {
	position: relative;
	width: 100%;
	padding-bottom: 56%;
	margin-bottom: 1.25rem;

	iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}
