.headlineImage {
	position: relative;
	background-color: $lightGrey;
	min-height: 200px;
	margin-bottom: 1.25rem;

	&__image {
		display: block;
		width: 100%;
	}

	&__caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: $color-one;
		color: $white;
		display: inline-block;
		padding: 12px 14px;

		h3 {
			margin: 0;
		}
	}
}
