
/* Font sizes */
$base-font-size: 16px;

// Fonts
// $sans-serif: 'vag-rundschrift-d', Arial, sans-serif;
$sans-serif: 'DHI', Arial, sans-serif;
$serif: 'Times New Roman', serif;

/* Colours */
$base-font-color: #2e2e2e;
$white: #fff;
$black: #000;
$grey: #4a4a4a;
$midGrey: #979797;
$lightGrey: #e3e3e3;

$color-one: #43276d; // PURPLE
$color-two: #e6007d; // PINK
$color-three: #53b04b; // GREEN
$color-four: #ef7a1a; // YELLOW
$color-five: #e52728; // RED-ORANGE
$color-six: #0072bb; // BLUE
$color-seven: #3ab8b9; // LIGHT-BLUE
