
.social {
	&__item {
		width: 45px;
		height: 45px;
		display: inline-block;
		background-size: contain;
		background-repeat: no-repeat;
		margin-right: 16px;
		transition: ease 0.4s;
		position: relative;

		&::before {
			position: absolute;
			content: '';
			top: -1px;
			left: -1px;
			right: -1px;
			bottom: -1px;
			border-radius: 1000px;
			background-color: rgba(255, 255, 255, 0);
		}

		&--facebook {
			background-image: url(/images/facebook.svg);
		}

		&--twitter {
			background-image: url(/images/twitter.svg);
		}

		&--linkedin {
			background-image: url(/images/linkedin.svg);
		}

		&--youtube {
			background-image: url(/images/youtube.svg);
		}

		&--whatsapp {
			background-image: url(/images/whatsapp.svg);
		}

		&--email {
			background-image: url(/images/email.svg);
		}

		&:hover {
			opacity: 0.7;

			&::before {
				background-color: rgba(255, 255, 255, 0.4);
			}
		}
	}

	&--share {
		background: $white;
		margin: -1rem -1rem 1rem -1rem;
		padding-bottom: 1rem;

		.social__item {
			width: 35px;
			height: 35px;
			margin-right: 8px;
		}

		h2 {
			margin: 0 0 5px;
		}

		&--desktop {
			display: none;
		}

		@media (min-width: 740px) {
			&--mobile {
				display: none;
			}

			&--desktop {
				display: block;
			}
		}
	}
}

