.map {
	position: relative;
	padding-bottom: 60%;

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}
